.svg-icon{
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: middle;
}

//21
.icon-linkedin-square,
.icon-twitter-square{
  width: 0.857421875em;
}

//22
.icon-search {
  width: 0.9287109375em;
}

// 15
.icon-angle-down,
.icon-angle-up,
.icon-search{
  width: 0.642578125em;
}

// 19
.icon-user-md {
  width: 0.7861328125em;
}

// 22

.icon-calendar-check-o {
  width: 0.9287109375em;
}

// 8
.icon-angle-right {
  width: 0.3388671875em;
}

// 9
.icon-angle-left {
  width: 0.375em;
}

//26
.icon-users-group {
  width: 1.0712890625em;
}

//27
.icon-briefcase {
  width: 1.119140625em;
}

// 28
.icon-enve-open {
  width: 1.158203125em;
}

// 31
.icon-graduation-cap{
  width: 1.2861328125em;
}

// <svg class="svg-icon icon-info"><use xlink:href="#icon-info"></use></svg>
// <svg class="svg-icon icon-linkedin-square"><use xlink:href="#icon-linkedin-square"></use></svg>
// <svg class="svg-icon icon-twitter-square"><use xlink:href="#icon-twitter-square"></use></svg>
// <svg class="svg-icon icon-search"><use xlink:href="#icon-search"></use></svg>
// <svg class="svg-icon icon-lock"><use xlink:href="#icon-lock"></use></svg>
// <svg class="svg-icon icon-graduation-cap"><use xlink:href="#icon-graduation-cap"></use></svg>
// <svg class="svg-icon icon-users-group"><use xlink:href="#icon-users-group"></use></svg>
// <svg class="svg-icon icon-angle-left"><use xlink:href="#icon-angle-left"></use></svg>
// <svg class="svg-icon icon-angle-right"><use xlink:href="#icon-angle-right"></use></svg>
// <svg class="svg-icon icon-angle-up"><use xlink:href="#icon-angle-up"></use></svg>
// <svg class="svg-icon icon-angle-down"><use xlink:href="#icon-angle-down"></use></svg>
// <svg class="svg-icon icon-bell-o"><use xlink:href="#icon-bell-o"></use></svg>
// <svg class="svg-icon icon-user-md"><use xlink:href="#icon-user-md"></use></svg>
// <svg class="svg-icon icon-briefcase"><use xlink:href="#icon-briefcase"></use></svg>
// <svg class="svg-icon icon-enve-open"><use xlink:href="#icon-enve-open"></use></svg>
// <svg class="svg-icon icon-calendar-check-o"><use xlink:href="#icon-calendar-check-o"></use></svg>
// <svg class="svg-icon icon-arrow-thin-down"><use xlink:href="#icon-arrow-thin-down"></use></svg>
// <svg class="svg-icon icon-arrow-thin-up"><use xlink:href="#icon-arrow-thin-up"></use></svg>
// <svg class="svg-icon icon-arrow-thin-left"><use xlink:href="#icon-arrow-thin-left"></use></svg>
// <svg class="svg-icon icon-arrow-thin-right"><use xlink:href="#icon-arrow-thin-right"></use></svg>
// <svg class="svg-icon icon-brand-facebook"><use xlink:href="#icon-brand-facebook"></use></svg>
// <svg class="svg-icon icon-brand-google"><use xlink:href="#icon-brand-google"></use></svg>
// <svg class="svg-icon icon-close"><use xlink:href="#icon-close"></use></svg>




//@include svg-size(viewbox width, new width);

