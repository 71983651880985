#skippy {
  display: block;
  padding: 1em;
  color: #fff;
  background-color: theme-color("primary");
  outline: 0;

  .skiplink-text {
    padding: .5em;
    outline: 1px dotted;
  }
}

.topbar{
  border-bottom: 1px solid $gray-400;

  &__container{
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .input-group{
      padding-right: 20px;

      .btn{
        min-width: 50px;
        color: #fff;
        background-color: $gray-500;
        @extend %transition-all;

        .svg-icon{
          @include svg-size(21px, 16px);
        }
       
        &:active,
        &:hover,
        &:focus{
          background-color: $gray-600;
        }
      }
    }

    nav{
      flex: 0 0 1%;
      padding: 0 10px;
      border-left: 1px solid $gray-400;
      border-right: 1px solid $gray-400;

      .svg-icon{
        @include svg-size(21px, 24px);
      }
    }

    .list-unstyled{
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      >li{
        padding: 10px 11px;

        a{
          color: $gray-500;
          @extend %transition-all;

          &:active,
          &:hover,
          &:focus{
            color: $gray-600;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .input-group{
      flex: 0 0 280px;
      max-width: 280px;
    }
  }
}

.toplink{
  @include rem(padding, 12px 15px 12px 15px);

  &__logo{
    text-align: center;
    @include rem(margin-bottom, 10px);

    a{
      display: inline-block;
    }
  }
  
  &__links{
    .list-unstyled{
      display: flex;
      align-items: center;
      justify-content: center;
      @include rem(margin-bottom, 10px);

      >li{
        @include rem(font-size, 15px);

        &:not(:last-child){
          margin-right: 25px;
        }

        .circle{
          position: relative;
          display: inline-block;
          color: #fff;
          @include rem(line-height, 24px);
          text-align: center;
          @include rem(width, 24px);
          @include rem(height, 24px);
          border-radius: 50%;
          @include rem(margin-left, 4px);
          background-color: theme-color("secondary");
          @extend %transition-all;
      
          .icon-search{
            @include svg-size(22px, 13px);
            margin-top: -1px;
          }

          .icon-lock{
            @include svg-size(15px, 10px);
            margin-top: -2px;
          }
        }

        a{
          color: $body-color;
          font-weight: 400;
          @extend %transition-all;
      
          &:hover,
          &:focus{
            color: theme-color("secondary");
            text-decoration: none;
          }
        }

        a:hover .circle{
          background-color: $link-color;
        }
      }
    }
  }

  @media (min-width: 1200px){
    width: 1170px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include rem(padding, 24px 15px 20px 15px);

    &__logo{
      text-align: left;
      margin-bottom: 0;
    }

    &__links,
    &__logo{
      flex: 0 1 auto;
    }

    &__links{
      .list-unstyled{
        justify-content: flex-start;
        margin-bottom: 0;
      }
    }
  }
}

// max-width 768px up down
// @include media-breakpoint-down(sm) {}
// min-width 769px & up
// @include media-breakpoint-up(md) {}
