
$vbw: 98px;
$vbh: 87px; // viewbox height
$new-width: 180px;

.footer{
  position: relative;
  @include rem(padding, 35px 20px 0px 20px);
  color: #cfdbfd;
  background-color:  rgb(77, 87, 114);

  &__art{
    position: absolute;
    top: 5%;
    right: 5%;
    left: 5%;
    bottom: 5%;

    svg{
      fill: #566283;
    }
  } 

  address{
    border-bottom: 1px solid #cfdbfd;
    @include rem(padding-bottom, 16px);

    a{
      @extend %transition-all;
      color: #cfdbfd;

      @include hover{
        color: #fff;
      }
    }

    p{
      margin-bottom: 5px;
    }
  }

  .list-unstyled{

    a{
      @extend %transition-all;
      color: #cfdbfd;

      @include hover{
        color: #fff;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__art{
      top: -10px;
      left: auto;
      bottom: auto;
      right: 20px;
      transform: rotate(10deg);
     
      svg{
        width: $new-width;
        height: (($vbh / $vbw) * $new-width);
      }
    } 

    address{
      border-bottom: 1px solid transparent;
      margin-bottom: 0;
      @include rem(padding-bottom, 1px);
    }
  }
}


.footer-tel-block{

  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.footer-link-block{

  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}