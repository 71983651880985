
html,body{
  min-height: 100%;
}

body{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: $body-color;
}

.form-control{
  @include rem(padding, 7px 12px);
  font-weight: 300;
  font-size: 15px;
  border-color: $gray-400;
}

.btn:not(.btn-lg, .btn-sm){
  @include rem(padding, 7px 12px);
}

.btn.focus, 
.btn:focus {
  box-shadow: none;
}

button[type="submit"]{
  cursor: pointer;
}

.btn-facebook{
  position: relative;
  background-color: #3B5998;
  border-color:  #3B5998;
  color: #fff;

  &:hover,
  &:focus,
  &:active{
    color: #fff;
    background-color: darken(#3B5998, 5%);
    border-color:  darken(#3B5998, 5%);
  }
}

b,strong,label{
  font-weight: 500;
}

.site-content{
  padding-top: 40px;
  padding-bottom: 20px;
  min-height: 250px;
}

.container-fluid{
  @media (min-width: 1200px){
    width: 1170px;
  }
}

.text-body-color{
  color: $body-color;
}



.sub-head{
  text-transform: uppercase;
  @include rem(font-size, 17px);
  @include rem(margin-bottom, 16px);

  &.font-size-24{
    @include rem(font-size, 24px);
  }

  .svg-icon{
      margin-right: 4px;
  }

  .icon-users-group{
    @include svg-size(26px, 20px);
    vertical-align: -12%;
  }

  .icon-graduation-cap{
    @include svg-size(31px, 24px);
  }

  .icon-user-md{
    @include svg-size(19px, 18px);
    vertical-align: -32%;
  }

  .icon-bell-o{
    @include svg-size(24px, 20px);
  }

  .icon-calendar-check-o{
    vertical-align: -11%;
  }

  .text-secondary{
    color: theme-color("secondary");
  }
}

.article-block{
  @include rem(padding, 20px);
  @include rem(margin-bottom, 24px);
  //border-top: 3px solid $body-color;
  border-top: 3px solid $gray-600;

  &.bg-light{
    background-color: $gray-200 !important;
  }

  ul,ol,p{
    @include rem(margin-bottom, 10px);
  }

  ul:last-child,
  p:last-child{
    margin-bottom: 0;
  }

  ul{
    a{
      color: $body-color;
    }
  }
}

.border-block{
  @include rem(padding, 20px);
  border: 1px solid $gray-400;
  @include rem(margin-bottom, 24px);

  &.bg-light{
    background-color: $gray-200 !important;
    border: 1px solid $gray-200;
  }

  ul,ol,p{
    @include rem(margin-bottom, 10px);
  }

  .icon-enve-open,
  .icon-briefcase{
    vertical-align: -5%;
  }

  ul:last-child,
  p:last-child{
    margin-bottom: 0;
  }

  ul{
    a{
      color: $body-color;
    }
  }
}



.has-icon{
  @include rem(padding-left, 18px);

  >li{
    position: relative;
    
    &:not(:last-child){
      margin-bottom: 5px;
    }
  }
}

.list-icon{
  display: inline-block;
  position: absolute;
  @include rem(left, -25px);
  @include rem(width, 25px);
  text-align: center;

  .svg-icon{
    vertical-align: -8%;
  }
}


.font-weight-400{
  @extend %font-weight-400;
}

.font-weight-300{
  @extend %font-weight-300;
}

.font-weight-500{
  @extend %font-weight-500;
}

.font-size-13{
  @extend %font-size-13;
}

.font-size-14{
  @extend %font-size-14;
}

.font-size-15{
  @extend %font-size-15;
}

.font-size-16{
  @extend %font-size-16;
}

.font-size-17{
  @extend %font-size-17;
}

.font-size-18{
  @extend %font-size-18;
}

.font-size-19{
  @extend %font-size-19;
}

.font-size-20{
  @extend %font-size-20;
}

// theme-color("light");
// theme-color("light-dark");
// theme-color("primary");
// theme-color("secondary");


//   @@include('inc_html/scripts.html')
// https://v4-alpha.getbootstrap.com/layout/responsive-utilities/

// max-width 768px up down
// @include media-breakpoint-down(sm) {}

// min-width 769px & up
// @include media-breakpoint-up(md) {}



// .cb{
//   text-align: center;

//   label{
//     margin: 0;
//     @extend %font-face-light;
//     @include rem(font-size, 14px);
//   }

//   >input[type="checkbox"],
//   >input[type="radio"] {
//     display: none;
//   }

//   >input[type="checkbox"]+label,
//   >input[type="radio"]+label{
//     display: block;
//     padding: 12px 0;
//     cursor: pointer;
//     color: #989898;
//     background-color: #EDEDED;
//     transition: background-color .15s linear;
//   }

//   >input[type="checkbox"]+label:hover,
//   >input[type="radio"]+label:hover{
//     background-color: darken(#EDEDED, 3%);
//   }
  
//   >input[type="checkbox"]:checked+label,
//   >input[type="radio"]:checked+label {
//     //background-color: theme-color("primary");
//     background-color: darken(#a9a8a8, 1%);
//     color: #fff;
//   }
// }

// .photo-upload{

//   .inputfile{
//     width: 0.1px;
//     height: 0.1px;
//     opacity: 0;
//     overflow: hidden;
//     position: absolute;
//     z-index: -1;
//   }

//   .inputfile + label {
//     max-width: 80%;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     cursor: pointer;
//     display: inline-block;
//     overflow: hidden;
//     color: rgba(#505050,.5);
//   }
//   .inputfile + label span{
//     display: inline-block;
//     //vertical-align: middle;
//     @include rem(font-size, 14px);
//     @extend %font-face-light;
//     text-transform: capitalize;
//   }

//   .inputfile + label svg {
//     margin-right: .75rem;
//     @include svg-size(24px, 22px);
//     vertical-align: -25%;
//   }
// }