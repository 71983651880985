


.btn-outline-link{
  position: relative;
  border-color: lighten($link-color, 15%);
  color: $body-color;
  text-align: left;
  text-transform: uppercase;
  @include rem(padding, 5px 14px);
  @include rem(font-size, 14px);
  @include rem(border-radius, 15px);
  background-color: #fff;
  @extend %transition-all;

  .circle {
    display: inline-block;
    position: absolute;
    top: 2.5px;
    right: 3px;
    color: #fff;
    text-align: center;
    @include rem(line-height, 26px);
    @include rem(width, 26px);
    @include rem(height, 26px);
    border-radius: 50%;
    background-color: $link-color;
    opacity: .8;
    transition: opacity .15s linear;
  }

  &:hover,
  &:focus,
  &:active{
    border-color: $link-color;
    color: $link-color;
  }

  &:hover .circle,
  &:focus .circle{
    opacity: 1;
  }
}