/*Bootstrap v4.0.0-beta (https://getbootstrap.com)*/

@import "bs4/functions";
@import "bs4/variables";
@import "bs4/mixins";

@import "global-modules/custom-mixin";
@import "global-modules/placeholder";

@import "bs4/root";
@import "bs4/print";
@import "bs4/reboot";
@import "bs4/type";
@import "bs4/images";
//@import "bs4/code";
@import "bs4/grid";
@import "bs4/tables";
@import "bs4/forms";
@import "bs4/buttons";
@import "bs4/transitions";
@import "bs4/dropdown";
@import "bs4/button-group";
@import "bs4/input-group";
@import "bs4/custom-forms";
@import "bs4/nav";
@import "bs4/navbar";
@import "bs4/card";
@import "bs4/breadcrumb";
@import "bs4/pagination";
@import "bs4/badge";
@import "bs4/jumbotron";
@import "bs4/alert";
@import "bs4/progress";
@import "bs4/media";
@import "bs4/list-group";
@import "bs4/close";
@import "bs4/modal";
@import "bs4/tooltip";
@import "bs4/popover";
@import "bs4/carousel";
@import "bs4/utilities";

@import "global-modules/scaffolding";
@import "global-modules/button-style";
@import "global-modules/svg-icon";


// header & footer
@import "global-modules/header";
@import "global-modules/navbar";
@import "global-modules/global-page";

@import "global-modules/footer";
