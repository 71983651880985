
//@import "global-modules/custom-mixin";
%transition-all{
	transition: all .15s linear;
}



%font-weight-300{
	font-weight: 300;
}

%font-weight-400{
	font-weight: 400;
}

%font-weight-500{
	font-weight: 500;
}


%font-size-13{
	@include rem(font-size, 13px);
}

%font-size-14{
	@include rem(font-size, 14px);
}

%font-size-15{
	@include rem(font-size, 15px);
}

%font-size-16{
	@include rem(font-size, 16px);
}

%font-size-17{
	@include rem(font-size, 17px);
}

%font-size-18{
	@include rem(font-size, 18px);
}

%font-size-19{
	@include rem(font-size, 19px);
}

%font-size-20{
	@include rem(font-size, 20px);
}