
.navbar-dark{
  @include rem(padding, 10px 15px);
  background-color: theme-color("primary");
  //border-bottom: 1px solid $gray-700;
  //border-bottom: 1px solid darken(theme-color("primary"), 5%);

  .navbar-nav{
    .nav-link{
      @include rem(font-size, 15px);
      @include rem(padding, 8px 10px);
      font-weight: 400;
      color: lighten(theme-color("primary"), 60%);
      display: block;
      @extend %transition-all;

      @include hover{
        color: #fff;
      }
    }
  }

  .navbar-nav{
    flex-basis: 100%;
  }

  @include media-breakpoint-up(md) {
    padding: 0;

    .navbar-nav{
      .nav-link{
        @include rem(font-size, 17px);
        @include rem(padding, 15px 21px);
        color: lighten(theme-color("primary"), 60%);
        border-bottom: 5px solid transparent;
       
        @include hover{
          color: #fff;
          border-bottom-color: theme-color("secondary");
        }
  
        &.active{
          border-bottom-color: theme-color("secondary");
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    border-bottom: 1px solid darken(theme-color("primary"), 13%);

    .navbar-toggler{
      padding: 0.25rem 0.65rem;
      color: #fff;
      border-color: lighten(theme-color("primary"), 15%);
    }
  }
}



.sm-full-width{
  @include media-breakpoint-down(sm) {
    &.container-fluid{
      padding-left: 0;
      padding-right: 0;
    }
  }
}