
.page{

}

.page-head{
  position: relative;
  height: 180px;
  border-top: 1px solid darken(theme-color("primary"), 5%);
  border-bottom: 1px solid $gray-700;

  &__image{
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center; 
    justify-content: center; 
    width: 100%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    z-index: 10;

    &::after{
      display: block;
      position: absolute; 
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; 
      background-image: linear-gradient(-45deg, rgba(169,62,229,.6), rgba(theme-color("primary"),.6));
    }

    &.gradient-1{

      &::after{
        background-image: linear-gradient(-45deg, rgba(theme-color("primary"),.7),rgba(169,62,229,.4));
      }
    }

    &.gradient-2{

      &::after{
        background-image: linear-gradient(-45deg, rgba(#5ff165, .3),rgba(#023955,.6));
      }
    }

    &.gradient-3{
      
      &::after{
        background-image: linear-gradient(-45deg, rgba(169,62,229,.6), rgba(#5ff165,.3));
      }
    }
  }

  &__title{
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center; 
    justify-content: center; 
    width: 100%;
    z-index: 15;

    h1,h2{
      @include rem(font-size, 30px); 
      color: #fff;
      @include rem(letter-spacing, 1.2px); 
      text-shadow: 2px 2px 0 rgba($gray-700,.8);
    }
  }

  @include media-breakpoint-up(md) {
    height: 180px;

    &__title{
      h1,h2{
        font-size: 2.5rem;
      }
    }
  }
}

.page-grid{

  &__col{
    position: relative;
    display: flex;
    flex-direction: column;
    @include rem(padding, 15px);
    border-bottom: 1px solid $gray-500;
    background-color: $gray-200;

    >.buttons{
      margin-top: auto;
      flex: 1;
    }
  }
 
  &__content{
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
    @include rem(margin-bottom, 15px);
   
    
    >article,
    >.article{
      flex: 1;
      
      p{
        @include rem(margin-bottom, 15px);
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    @include rem(margin, 0 -15px);

    &__col{
      flex: 0 1 percentage(1/3);
      max-width:  percentage(1/3);
      @include rem(padding, 0px 15px 20px 15px);
      border-right: 1px solid $gray-400;
      border-bottom: none;
      background-color:#fff;

      &:nth-child(3n+3){
        border-right: 1px solid transparent;
      }
    }
  }
}